.payment-page {
  background-color: #f9f9f9;
  padding: 40px 0;
}

.payment-form {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.payment-form .form-control {
  border-radius: 5px;
  height: 45px;
}

.payment-form .btn-primary {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.payment-form .btn-primary:hover {
  background-color: #0056b3;
}

.payment-form .list-group-item {
  border: none;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  line-height: 1.5;
}

.payment-form .card-element {
  background-color: #f1f1f1;
  padding: 12px;
  border-radius: 5px;
}
/* Overlay */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999; /* Ensures the modal appears above everything else */
}

/* Modal Content */
.custom-modal-content {
  position: relative;
  width: 80%;
  max-width: 500px;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 9999999; /* Ensures the modal appears above everything else */
}

/* Modal Title */
.modal-title {
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Modal Buttons */
.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}


.icon-container {
  background: linear-gradient(195deg, #ec407a, #d81b60);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  top: -30px;
  left: 20px;
}
/* 
.feature-card {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.gradient-icon {
  color: white;
  background: linear-gradient(195deg, #ec407a, #d81b60);
  padding: 10px;
  border-radius: 50%;
}
