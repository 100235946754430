.main-page {
  padding: 60px;

  .main-page__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px;
    background-color: #fff;
  }

  .main-page__logo {
    height: 80px;
  }

  a {
    text-decoration: none;
    color: #000;
  }

  a span {
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
    transform: translate(5px, -2px);
  }

  a img {
    width: 30px;
  }

  .main-page__hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 70px;
    background-color: #f9f9f9;
  }

  .main-page__hero-content {
    max-width: 50%;
  }

  .main-page__hero-content h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }

  .main-page__cta-button {
    font-size: 18px;
    padding: 13px 30px;
    border: 1px solid rgb(251, 113, 133);
    border-radius: 25px;
    cursor: pointer;
  }

  .main-page__cta-button:hover {
    background: linear-gradient(to bottom, #c6117d 0%, #e50471 100%);
    color: white;
  }

  .main-page__hero-image {
    height: auto;
  }

  .borderline {
    background: linear-gradient(to bottom, #c6117d 0%, #e50471 100%);
  }

  .view-feature {
    padding: 12px 30px;
    border: 1px solid rgb(251, 113, 133);
    border-radius: 25px;
    cursor: pointer;
  }

  .view-feature:hover {
    background: linear-gradient(to bottom, #c6117d 0%, #e50471 100%);
    color: white;
  }

  .main-page__numbers,
  .main-page__features,
  .main-page__premium,
  .main-page__testimonials {
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }

  .main-page__numbers h2,
  .main-page__features h2,
  .main-page__premium h2,
  .main-page__testimonials h2 {
    font-size: 38px;
    margin-bottom: 30px;
  }

  .main-page__numbers > p,
  .main-page__features > p,
  .main-page__premium > p,
  .main-page__testimonials > p {
    text-align: center;
    font-size: 24px;
    font-family: sans-serif;
    width: 80%;
    margin: 10px auto;
  }

  .main-page__features {
    background-color: #eceff7;
  }

  .main-page__features-container {
    display: flex;
  }

  .main-page__features-container img {
    height: 350px;
    margin-top: 5%;
  }

  .main-page__features-container button {
    margin-top: 40px;
    background-color: #144c96;
    color: #fff;
    font-size: 18px;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
  }

  .main-page__numbers-options {
    display: flex;
    gap: 1rem;
    padding-left: 40px;
    padding-right: 40px;
    flex-direction: row;
    justify-content: space-around;
  }

  .main-page__number-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
    border: 3px solid transparent;
    border-image: linear-gradient(to top, #c6117d 0%, #e50471 100%) 1;
    transition: transform 0.3s ease-in-out;
    height: 500px;
  }

  .main-page__number-option img {
    height: 200px;
    width: 200px;
  }

  .main-page__number-option h3 {
    font-size: 20px;
    font-weight: 700;
  }

  .main-page__number-option button {
    padding: 10px 20px;
    border: 1px solid rgb(251, 113, 133);
    font-size: 16px;
    border-radius: 25px;
    cursor: pointer;
  }

  .main-page__number-option button:hover {
    background: linear-gradient(to bottom, #c6117d 0%, #e50471 100%);
    color: white;
  }

  .main-page__number-option:hover {
    transform: scale(1.04);
  }

  .main-page__features-list .main-page__feature,
  .main-page__features-list .main-page__premium-feature {
    max-width: 200px;
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  .main-page__features-list .main-page__feature h3,
  .main-page__features-list .main-page__premium-feature h3 {
    font-size: 20px;
    font-weight: 700;
    display: inline;
  }

  .main-page__features-list .main-page__feature p,
  .main-page__features-list .main-page__premium-feature p {
    font-size: 16px;
    margin-left: 10px;
    text-align: left;
  }

  .main-page__premium-image {
    max-width: 40%;
  }
}

.price-options .dropdownItem:hover {
  background-color: #ffffff !important;
}

.premium-price-features .dot {
  background-color: #fcd164;
  border-color: #f9e8bb;
}

.feature-link {
  color: #1a73e8;
  font-weight: 600;
  padding: 20px 0;
  font-size: 24px !important;
}

.focus\:shadow-blue:focus {
  box-shadow: 0 0 8px 2px rgba(59, 130, 246, 0.75);
}

.getTouch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 2.3rem;
  background: linear-gradient(to bottom, #c6117d 0%, #e50471 100%);
  color: white;
}

.message {
  padding: 10px 30px;
  border: 1px solid rgb(251, 113, 133);
  font-size: 23px;
  border-radius: 28px;
  cursor: pointer;
}

.message:hover {
  background: linear-gradient(to bottom, #c6117d 0%, #e50471 100%);
  color: white;
}

.getTouch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 2.3rem;
  background: linear-gradient(to bottom, #c6117d 0%, #e50471 100%);
  color: white;
}

.WhatsgetTouch {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 2.3rem;
  background: linear-gradient(to bottom, #c6117ec7 0%, #e50471b6 10%);
  color: white;
}

.messageBox {
  padding: 6px 20px;
  background: linear-gradient(to bottom, #c6117d 0%, #e50471 100%);
  color: white;
  font-size: 20px;
  border-radius: 28px;
  cursor: pointer;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
}

.accordion-content.open {
  max-height: 500px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.text-gradient {
  background: linear-gradient(45deg, #c6117d, #e50471);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-gradient-to-top {
  background: linear-gradient(180deg, #e50471, #c6117d);
}

.border-pink {
  border: 2px solid #e50471;
}

.btn-outline-danger {
  color: #e50471;
  border-color: #e50471;
}

.btn-outline-danger:hover {
  background-color: #e50471;
  color: white;
}

.card {
  transition: transform 0.3s ease;
}
/* 
.card:hover {
  transform: scale(1.05);
} */


.text-gradient {
  background: linear-gradient(45deg, #c6117d, #e50471);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.link-pink {
  color: #e50471;
  font-weight: 500;
}

.link-pink:hover {
  text-decoration: underline;
}

.btn-outline-danger {
  border-color: #e50471;
  color: #e50471;
}

.btn-outline-danger:hover {
  background-color: #e50471;
  color: white;
}

section:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease;
}